import React from "react"

import Layout from "~components/layout"
import SEO from "~components/seo"
import UseCaseHero from "~content/use-case/Hero"
import UseCaseServicing from "~content/use-case/Servicing"
import UseCaseIndustries, { defaultIndustriesList } from "~content/use-case/Industries"
import Community from "~content/use-case/Community"

import heroImage from "~images/use-case/buy-now-pay-later-hero.png"

const UseCaseBuyNowPayLaterPage: React.FC = () => {
  return (
    <Layout>
      <SEO
        canonicalTag="https://canopyservicing.com/use-case/buy-now-pay-later"
        description="Make your mark with differentiated Buy Now Pay Later programs for consumers and businesses."
        title="Buy Now Pay Later | Canopy Servicing"
      />
      <UseCaseHero
        title="Buy Now Pay Later"
        body="Create and manage a Buy Now Pay Later solution for any industry with multiple program variations."
        image={heroImage}
      />
      <UseCaseServicing
        bullet1="Real-time calculations"
        bullet2="Event-driven automations"
        bullet3="Pre-built popular integrations"
      />
      <UseCaseIndustries industries={defaultIndustriesList} />
      <Community />
    </Layout>
  )
}

export default UseCaseBuyNowPayLaterPage
